<template>
  <div class="shop">
    <left-tabs :tabList="leftList" />
    <router-view></router-view>
  </div>
</template>

<script>
import LeftTabs from "@/components/leftTabs";
export default {
  components: { LeftTabs },
  name: "newsManage",
  data() {
    return {
      leftList: [
        { id: 1, name: "资讯列表", url: '/newsManage/list' },
        { id: 2, name: "资讯分类", url: '/newsManage/cate' },
        { id: 2, name: "轮播图", url: '/newsManage/newsBanner' },
      ],
    };
  },
  methods: {},

};
</script>

<style scoped lang='less'>
  .shop {
    display: flex;
  }
</style>
